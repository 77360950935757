import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MessageModal } from '../modals/message/message.modal';
import {
  LoginDetails,
  LoginReqData,
  RegistrationDetails,
} from '../models/auth';
import { GlobalRequest } from '../models/global';
import { RecoverPasswordDto } from '../pages/landing/models/dto/recover-password.dto';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _baseURL = environment.baseURL;
  message: string = ''

  constructor(private _Http: HttpClient, private _router: Router,
    private _loadingService: LoadingService,
    private _matDialog: MatDialog
  ) { }

  public Login(loginDetails: LoginDetails): Observable<LoginReqData> {
    return this._Http.post<LoginReqData>(
      this._baseURL + '/auth/login',
      loginDetails
    ).pipe(catchError((err: HttpErrorResponse) => {
      if (err.status === 400) {
        this._loadingService.modalChangeSpinnerClass('loading__none')
        this.message = `Գաղտնաբառը սխալ է լրացված`
        const dialogRef = this._matDialog.open(MessageModal, { data: { message: this.message } })
        dialogRef.afterClosed().subscribe({
          next: () => {
          }
        })
      }
      if (err.status === 404) {
        this._loadingService.modalChangeSpinnerClass('loading__none')
        this.message = `Տվյալները սխալ են լրացված`
        const dialogRef = this._matDialog.open(MessageModal, { data: { message: this.message } })
        dialogRef.afterClosed().subscribe({
          next: () => {
          }
        })
      }
      if (err.status === 500) {
        this._loadingService.modalChangeSpinnerClass('loading__none')
        this.message = `Տվյալները սխալ են լրացված`
        const dialogRef = this._matDialog.open(MessageModal, { data: { message: this.message } })
        dialogRef.afterClosed().subscribe({
          next: () => {
          }
        })
      }

      return throwError(err)
    }));
  }

  public Registration(
    registrationDetails: RegistrationDetails
  ): Observable<GlobalRequest<null | string>> {
    return this._Http.post<GlobalRequest<null | string>>(
      this._baseURL + '/auth/register',
      registrationDetails
    ).pipe(catchError((err: HttpErrorResponse) => {
      if (err.status === 409) {
        // this.message = err.message
        this._loadingService.modalChangeSpinnerClass("loading__none")
        this.message = 'Տվյալ էլեկտրոնային հասցեն արդեն գրանցված է'
        const dialogRef = this._matDialog.open(MessageModal, { data: { message: this.message } })
        dialogRef.afterClosed().subscribe({
          next: () => {
          }
        })
      }
      return throwError(err)
    }));
  }

  public logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('organization');
    this._router.navigate(['landing']);
  }

  public forgotPassword(email: string) {
    return this._Http.get(this._baseURL + `/forgetPassword/sendEmailForgetPassword/?email=${email}`).pipe(catchError((err: HttpErrorResponse) => {

      return throwError(err)
    }))
  }

  public RecoverPassword(recoverDetails: RecoverPasswordDto) {
    return this._Http.post(this._baseURL + '/forgetPassword/setNewPasswordForget', recoverDetails)
  }
}
